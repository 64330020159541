import React, { useState } from 'react';
import '../CSS Files/Withdraw.css';
import { useEffect } from 'react';

function Withdraw() {
    const [balance, setBalance] = useState(0);

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    useEffect(() => {
        const initialValue = parseFloat(getCookie('valor')) || 0.0;
        setBalance(initialValue);
    }, []);
 // Current available amount
  const [selectedOption, setSelectedOption] = useState(null); // Selected payment option
  const [errorMessage, setErrorMessage] = useState(''); // Error message
  const minWithdrawalAmount = 4000.00; // Minimum required amount for withdrawal

  const toggleButton = (option) => {
    if (balance < minWithdrawalAmount) {
      setErrorMessage(`You need at least $${minWithdrawalAmount.toFixed(2)} to make a withdrawal.`);
      setSelectedOption(null); // Reset any selected option if the balance is insufficient
    } else {
      setErrorMessage(''); // Clear any previous error message
      setSelectedOption(option); // Set the selected payment option
    }
  };

  return (
    <div id="saque">
      <img 
            style={{ 
                maxWidth: '100%',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
            
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt="logo" 
        />

      <div className="valor-box-saque">
        <p className="withdraw-title">Available for withdrawal</p>
        <span className="valor-saque">${balance.toFixed(2)}</span>
      </div>

      {/* Display the error message if present */}
      {errorMessage && (
        <div className="error-message">
          <p>{errorMessage}</p>
        </div>
      )}

      <div className="button-container">
        <button className={`square-button ${selectedOption === 1 ? 'selected' : ''}`} onClick={() => toggleButton(1)}>
          <img src="images/paypal-3384015_1280.jpg" alt="Paypal" />
        </button>

        <button className={`square-button ${selectedOption === 2 ? 'selected' : ''}`} onClick={() => toggleButton(2)}>
          <img src="images/paynovo.jpg" alt="Paynovo" />
        </button>

        <button className={`square-button ${selectedOption === 3 ? 'selected' : ''}`} onClick={() => toggleButton(3)}>
          <img src="images/ebanx%20novo.jpg" alt="Ebanx" />
        </button>

        <button className={`square-button ${selectedOption === 4 ? 'selected' : ''}`} onClick={() => toggleButton(4)}>
          <img src="images/monzo.jpg" alt="Monzo" />
        </button>
      </div>

      {selectedOption && !errorMessage && (
        <div className="selected-message">
          <p>You have selected option {selectedOption}</p>
        </div>
      )}
    </div>
  );
}

export default Withdraw;
