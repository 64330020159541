import React from 'react';
import ReactDOM from 'react-dom/client';
import './CSS Files/index.css';
import App from './JavaScripts/login'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    
    <App />
  </React.StrictMode>
);

serviceWorkerRegistration.register();