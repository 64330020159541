import React, { useEffect, useState, useCallback, useRef } from 'react';
import '../CSS Files/LikeForMoney.css';

function LikeForMoney() {
  const [balance, setBalance] = useState(0);
  const [evaluationCount, setEvaluationCount] = useState(0);
  const [nTime, setCooldownTime] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showGif, setShowGif] = useState(false); // For displaying the GIF pop-up
  const [ads, setAds] = useState([]);
  const [earningsPerAd, setEarningsPerAd] = useState(2); // Starting earnings per evaluation
  const adContainerRef = useRef(null);
  const [randomEarnings, setRandomEarnings] = useState(0); // Add this state to store random earnings


  const MAX_EVALUATIONS = 10; // Maximum evaluations before limit
  const COOLDOWN_PERIOD = 3 * 60 * 60 * 1000; // 3 hours in milliseconds

  // Shuffle ads
  const shuffleArray = useCallback((array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  }, []);

  const getRandomEarnings = (min, max) => {
    return (Math.random() * (max - min) + min).toFixed(2);
  };

  const generateShuffledAds = useCallback(() => {
    const len_ads = 39;
    const newAds = Array.from({ length: len_ads }, (_, index) => `${process.env.PUBLIC_URL}images/ad${index + 1}.png`);
    return shuffleArray(newAds);
  }, [shuffleArray]);

  // Check cooldown status and evaluation count on page load
  useEffect(() => {
    const initialValue = parseFloat(getCookie('valor')) || 100.0;
    setBalance(initialValue);
    setAds(generateShuffledAds());

    // Check evaluation count and cooldown time from localStorage
    const storedEvaluations = parseInt(localStorage.getItem('evaluationCount')) || 0;
    const storedCooldown = parseInt(localStorage.getItem('cooldownTime')) || null;
    const storedEarnings = parseInt(localStorage.getItem('earningsPerAd')) || 2;

    setEarningsPerAd(storedEarnings);

    if (storedCooldown && Date.now() < storedCooldown) {
      setCooldownTime(storedCooldown);
      setShowPopup(true); // Show the popup if they return before the cooldown ends
    }

    setEvaluationCount(storedEvaluations);
  }, [generateShuffledAds]);

  // Set cookie utility function
  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  };

  // Get cookie utility function
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  // Handle evaluation
  const increaseBalance = (event) => {
    if (evaluationCount < MAX_EVALUATIONS) {
      // disable to click on the same ad again
      const adIndex = parseInt(event.target.parentElement.getAttribute('data-annuncio'));
      const adButtons = document.querySelectorAll(`.rating-buttons[data-annuncio="${adIndex}"] .rating-button`);
      adButtons.forEach((button) => (button.disabled = true));

      const earnings = parseFloat(getRandomEarnings(2.34, 8.94)); // Get random earnings
      setRandomEarnings(earnings); // Set it in the state
      const newValue = balance + earnings; // Use the random earnings value
      setBalance(newValue);
      setCookie('valor', newValue.toFixed(2), 365);
  
      const newEvaluationCount = evaluationCount + 1;
      setEvaluationCount(newEvaluationCount);
      localStorage.setItem('evaluationCount', newEvaluationCount); // Persist evaluations
  
      // Show GIF and random earnings animation
      setShowGif(true);
      setTimeout(() => {
        setShowGif(false);
      }, 3000); // Show for 3 seconds
  
      // Play the sound effect for 3 seconds
      const audio = document.getElementById('ratingSound');
      audio.play(3);
  
      if (newEvaluationCount >= MAX_EVALUATIONS) {
        startCooldown(); // Start cooldown when max evaluations are reached
      }
    }
  };
  // Start the cooldown
  const startCooldown = () => {
    const cooldownEndTime = Date.now() + COOLDOWN_PERIOD;
    setCooldownTime(cooldownEndTime);
    localStorage.setItem('cooldownTime', cooldownEndTime); // Persist cooldown
    setShowPopup(true); // Show the popup when limit is reached

    // After cooldown, increment the earnings by $2
    const newEarningsPerAd = earningsPerAd + 2;
    localStorage.setItem('earningsPerAd', newEarningsPerAd); // Store incremented earnings
    setEarningsPerAd(newEarningsPerAd); // Update state with new earnings
  };

  // Handle scroll to load more ads
  const handleScroll = () => {
    if (adContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = adContainerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 200) {
        setAds((prevAds) => [...prevAds, ...generateShuffledAds()]);
      }
    }
  };

  // Handle payment or waiting option
  const handleContinue = () => {
    setShowPopup(false);
    setEarningsPerAd(earningsPerAd); // Keep earnings incremented after cooldown
  };

  return (
    <div className="App">
      <div className="menu">
        <a href="/dashboard">
          <img src="/images/logo.png" alt="Logo" className="logo" />
        </a>
        <div className="buttons-container">
          <div className="valor-box">
            <div id="valor" style={{ fontFamily: 'Montserrat, sans-serif' }}>
              $ {balance.toFixed(2)}
            </div>
          </div>
        </div>
      </div>

      <div className="slogan-inicio" style={{ backgroundColor: '#0178f6' }}>
        <span className="material-symbols-outlined" style={{ fontSize: '35px', color: 'white' }}>
          info
        </span>
        <div>
          <p
            style={{
              float: 'left',
              paddingLeft: '15px',
              color: 'white',
              fontWeight: 'bold',
              fontSize: '17px',
              paddingTop: '0px',
            }}
          >
            Increase your balance
            <br />
            evaluating the ads below
          </p>
        </div>
      </div>

      {/* Show the GIF when balance is increased */}
      {showGif && (
        <div className="notificacaoDinheiro" style={{ display: 'block', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000 }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', padding: '10px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
          <img src={`${process.env.PUBLIC_URL}/coin.gif`} alt="GIF" style={{ width: '50px', height: '50px' }} />
          <span style={{ paddingLeft: '10px', fontWeight: 'bold' }}>
              {randomEarnings >= 0 ? `+${randomEarnings}$` : `${randomEarnings}$`} added
            </span>
          </div>
        </div>
      )}

      {/* Show popup if evaluations reached limit */}
      {showPopup && (
        <div className="popup-containerL">
          <span className="popup-textL" style={{ fontSize: '24px', fontWeight: 'bold' }}>
            You've reached your evaluation limit!
          </span>
          <div style={{ marginBottom: '15px' }}></div>
          <p className="popup-textL" style={{ marginBottom: '10px', fontSize: '18px', fontWeight: 'bold' }}>
            Do you want to continue evaluating ads?
          </p>
          <button
            className="desbloquear-buttonL"
            style={{ fontWeight: 'bold', backgroundColor: '#00C45A' }}
            onClick={handleContinue}
          >
            Pay to continue
          </button>
          <div style={{ marginBottom: '15px' }}></div>
          <p className="popup-textL" style={{ marginBottom: '0px' }}>Or wait 3 hours</p>
        </div>
      )}

      <div
        id="adContainer"
        ref={adContainerRef}
        onScroll={handleScroll}
        style={{ height: 'calc(100vh - 150px)', overflowY: 'auto' }}
      >
        {ads.map((ad, index) => (
          <div key={index} style={{ marginBottom: '20px' }}>
            <img src={`/${ad}`} alt={`Ad ${index + 1}`} style={{ width: '60%', height: 'auto', display: 'block', margin: '0 auto' }} />
            <div style={{ marginBottom: '10px' }}></div>
            <div className="rating-buttons" data-annuncio={index}>
              {[1, 2, 3, 4, 5].map((rating) => (
                <button key={rating} className="rating-button" onClick={increaseBalance}>
                  {'⭐'.repeat(rating)}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>

      <audio id="ratingSound" src="/balance_sound.mp3"></audio>
    </div>
  );
}

export default LikeForMoney;
