import React, { useEffect, useState } from 'react';
import { RefreshCcw, DollarSign, HeadphonesIcon } from 'lucide-react';
import '../CSS Files/dashboard.css';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

function Dashboard() {
    const navigate = useNavigate();
    const [balance, setBalance] = useState(0);

    const handleRefundClick = () => {
        window.location.href = '/refound/pag1/index.html';  // This forces the browser to reload the page fully.
      };

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    //check is valor cookie is present else redirect to login
    if (!getCookie('valor')) {
        navigate('/');
    }


    useEffect(() => {
        if (!isMobile) {
            return (
              <div>
                <h1>This PWA is only available on mobile devices</h1>
              </div>
            );
          }
    
        const initialValue = parseFloat(getCookie('valor')) || 100.0;
        setBalance(initialValue);
    }, []);

    const handleLikeForMoneyClick = () => {
        navigate('/like-for-money');
    };

    const handleWithdrawClick = () => {
        navigate('/withdraw');
    };

    
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            background: 'white',
            padding: '20px'
        }}>
            <img
                style={{
                    maxWidth: '100%',
                    height: 'auto',
                }}
                src="images/logo.png"
                alt="logo"
            />
            <div style={{
                color: 'black',
                fontSize: '1.2rem',
                textAlign: 'center'
            }}>
                WELCOME TO THE SAFE SPACE!!
            </div>

            {/* Current Balance */}
            <div style={{
                fontSize: '1.2rem',
                marginTop: '20px',
                color: 'black',
                textAlign: 'center',
                marginBottom: '20px',
            }}>
                YOUR CURRENT BALANCE : <span style={{ backgroundColor: '#1F63ED', padding: '5px', color: 'white' }}>${balance.toFixed(2)}</span>
            </div>

            {/* Like For Money Button */}
            <button
                style={{
                    width: '100%',
                    maxWidth: '400px',
                    padding: '15px 20px',
                    backgroundColor: '#1F63ED',
                    borderRadius: '10px',
                    marginBottom: '20px',
                    border: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    transition: 'background-color 0.3s ease',
                }}
                onClick={handleLikeForMoneyClick}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#1451B1')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#1F63ED')}
            >
                <DollarSign size={24} style={{ marginRight: '10px' }} />
                <div style={{ fontSize: '18px', fontWeight: 'bold' }}>LIKE FOR MONEY</div>
            </button>

            {/* Withdraw Button */}
            <button
                style={{
                    width: '100%',
                    maxWidth: '400px',
                    padding: '15px 20px',
                    backgroundColor: '#1F63ED',
                    borderRadius: '10px',
                    marginBottom: '20px',
                    border: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    transition: 'background-color 0.3s ease',
                }}
                onClick={handleWithdrawClick}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#1451B1')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#1F63ED')}
            >
                <div style={{ marginRight: '10px' }}>💸</div>
                <div style={{ fontSize: '18px', fontWeight: 'bold' }}>WITHDRAW</div>
            </button>

            {/* Refund Button */}
            ;

            <div
                onClick={handleRefundClick}
                style={{
                    width: '100%',
                    maxWidth: '400px',
                    padding: '15px 20px',
                    backgroundColor: '#1F63ED',
                    borderRadius: '10px',
                    marginBottom: '20px',
                    textDecoration: 'none',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transition: 'background-color 0.3s ease',
                    cursor: 'pointer',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#1451B1')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#1F63ED')}
            >
                <RefreshCcw size={24} style={{ marginRight: '10px' }} />
                <div style={{ fontSize: '18px', fontWeight: 'bold' }}>REFUND</div>
            </div>

            {/* Contact Support Button */}
            <a
                href="https://wa.me/447939921192"
                target="_blank"
                rel="noreferrer"
                style={{
                    width: '100%',
                    maxWidth: '400px',
                    padding: '15px 20px',
                    backgroundColor: '#1F63ED',
                    borderRadius: '10px',
                    textDecoration: 'none',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transition: 'background-color 0.3s ease',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#1451B1')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#1F63ED')}
            >
                <HeadphonesIcon size={24} style={{ marginRight: '10px' }} />
                <div style={{ fontSize: '18px', fontWeight: 'bold' }}>CONTACT SUPPORT</div>
            </a>
        </div>
    );
}

export default Dashboard;
