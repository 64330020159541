import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import '../CSS Files/login.css';
import { AtSign, User } from 'lucide-react';
import Dashboard from './dashboard.js';
import LikeForMoney from './LikeForMoney';
import Withdraw from './Withdraw.js';
import { isMobile } from 'react-device-detect';


function LoginPage() {
  const navigate = useNavigate();
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPopup, setShowInstallPopup] = useState(false);

  useEffect(() => {
    // Check for cookie and redirect to dashboard if available
    const valorCookie = document.cookie.split('; ').find(row => row.startsWith('valor='));
    if (valorCookie) {
      navigate('/dashboard');
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });

    return () => {
      window.removeEventListener('beforeinstallprompt', () => {});
    };
  }, [navigate]);

  const handleSignIn = () => {
    // Here you can add authentication logic
    // For now, we'll just set a cookie and show the install popup
    document.cookie = "valor=100; path=/";
    setShowInstallPopup(true);
  };

  const handleAddToHomeScreen = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null);
        setShowInstallPopup(false);
        navigate('/dashboard');
      });
    } else {
      // If deferredPrompt is not available, just navigate to dashboard
      navigate('/dashboard');
    }
  };

  return (
    <div className="LOGIN-PAGE">
      <div className="div">
        <div className="overlap">
          <img 
            style={{ 
              maxWidth: '100%', 
              height: '100%', 
              display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0 auto'
            }}
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt="logo" 
          />
          <div className="text-wrapper">Welcome Back!</div>
        </div>
        <div className="text-wrapper-2">Login to your account</div>
        <div className="overlap-wrapper">
          <div className="overlap-group">
            <User className="person" />
            <textarea className="text-wrapper-4" placeholder="Name" />
          </div>
        </div>
        <div className="group">
          <div className="overlap-group">
            <AtSign className="at-sign" />
            <textarea className="text-wrapper-3" placeholder="Email" />
          </div>
        </div>
        <button className="sign-in-button" onClick={handleSignIn}>
          <div className="text-wrapper-5">Sign In</div>
        </button>
        {showInstallPopup && (
          <div id="popup" className="popup">
            <div className="popup-content">
              <div className="popup-body">
                <p>Install this application on your home screen for a better experience.</p>
                <button className="add-to-home-screen-button" onClick={handleAddToHomeScreen}>
                  <div className="text-wrapper-6">Install</div>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function App() {
  if (!isMobile) {
    return (
      <div>
        <h1>This PWA is only available on mobile devices</h1>
      </div>
    );
  }
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/like-for-money" element={<LikeForMoney />} />
        <Route path="/withdraw" element={<Withdraw />} />
      </Routes>
    </Router>
  );
}

export default App;
